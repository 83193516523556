import React from 'react';
import { Box, Flex, Text, Link, Image, IconButton } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaWhatsapp,
} from 'react-icons/fa';
import coloLogo from '../assets/colo_logo.png';

const Footer = () => {
  const scrollToAbout = () => {
    // Implement your scrolling logic here
  };

  return (
    <Box
      bg="primary.500"
      color="white"
      p={4}
      fontFamily="sans-serif"
      bottom={0}
      zIndex={999}
    >
      <Flex
        justify={{ base: 'center', md: 'space-between' }}
        align="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box>
          {/* Uncomment the Image component when you have the logo */}
          {/* <Image
            src={coloLogo}
            alt="Company Logo"
            width="150px"
            height="auto"
          /> */}
        </Box>

        <Flex
          mt={{ base: 4, md: 0 }}
          direction="column"
          align={{ base: 'center', md: 'flex-end' }}
        >
          <Text>&copy; 2024 Coolo Pops</Text>
          {/* <Flex mt={2}>
            <Link as={RouterLink} to="/terms" mx={2}>
              Terms of Service
            </Link>
            <Link as={RouterLink} to="/privacy" mx={2}>
              Privacy Policy
            </Link>
            <Link as={RouterLink} to="/contact" mx={2}>
              Contact Us
            </Link>
          </Flex> */}

          {/* Social Media Icons */}
          <Flex mt={4}>
            <Link
              href="https://www.facebook.com/cooloicepops"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                aria-label="Facebook"
                icon={<FaFacebook />}
                variant="ghost"
                color="white"
                fontSize="20px"
                mr={2}
              />
            </Link>

            <Link
              href="https://www.instagram.com/cooloicepops"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                aria-label="Instagram"
                icon={<FaInstagram />}
                variant="ghost"
                color="white"
                fontSize="20px"
                mr={2}
              />
            </Link>
            {/* Add WhatsApp redirection */}
            <Link
              href="https://wa.me/9822295295"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconButton
                aria-label="WhatsApp"
                icon={<FaWhatsapp />}
                variant="ghost"
                color="white"
                fontSize="20px"
                mr={2}
              />
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
