import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Footer from './components/Footer';
import Header from './components/Header';
import Gallery from './pages/Gallery';
import Home from './pages/Home';

const theme = extendTheme({
  styles: {
    global: {
      body: {
        scrollBehavior: 'smooth',
        padding: '0px',
      },
    },
  },
  colors: {
    primary: {
      500: '#2B4D94',
    },
    secondary: {
      500: '#0B9ADD',
    },
  },
  components: {
    // Customize specific components
    Box: {
      baseStyle: {
        boxShadow: 'lg', // Add a subtle shadow to all boxes
      },
    },
    Button: {
      baseStyle: {
        _focus: { boxShadow: 'outline' }, // Add a focus outline to buttons
      },
    },
    // Add more component customizations as needed
  },
});

function App() {
  return (
    <Router>
      <ChakraProvider theme={theme}>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          {/* <Route path="/gallery" element={<Gallery />} /> */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </ChakraProvider>
    </Router>
  );
}

export default App;
