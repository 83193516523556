import { Box, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import coloLogo from '../assets/colo_logo.png';

const navLinks = [
  { id: 'banner', label: 'Home' },
  { id: 'about', label: 'About Us' },
  // { id: 'gallery', label: 'Gallery' },
  { id: 'products', label: 'Products' },
  { id: 'contact', label: 'Contact' },
];

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = id => {
    console.log(id);
    if (id === 'gallery' && !location.pathname.includes('/gallery')) {
      // Navigate to the gallery page only if not already on the gallery page
      navigate('/gallery');
    } else if (id === 'banner' && location.pathname.includes('/gallery')) {
      // Scroll to the "banner" section on the home page
      navigate('/home');
      const bannerElement = document.getElementById('banner');
      if (bannerElement) {
        bannerElement.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // Scroll to other sections
      const myElement = document.getElementById(id);
      if (myElement) {
        myElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <Box
      bg="primary.500"
      p={4}
      color="white"
      fontFamily="sans-serif"
      boxShadow="md"
      position="sticky"
      top={0}
      zIndex={999}
    >
      <Flex
        justify={{ base: 'center', md: 'space-between' }}
        align="center"
        direction={{ base: 'column', md: 'row' }}
      >
        <Box mb={{ base: 4, md: 0 }}>
          <Image
            src={coloLogo}
            alt="Company Logo"
            width={{ base: '110px', md: '155px' }}
            height={{ base: '50px', md: '70px' }}
            mb={{ base: 2, md: 0 }}
          />
        </Box>

        <Flex>
          {navLinks.map(({ id, label }) => (
            <Box
              key={id}
              cursor="pointer"
              mr={4}
              _hover={{
                textDecoration: 'underline',
                transition: 'color 0.3s, text-decoration 0.3s',
              }}
            >
              {/* Use Link component for "Gallery" and "Home" */}
              {id === 'gallery' || id === 'home' ? (
                <Link
                  to={id === 'gallery' ? '/gallery' : '/'}
                  style={{ color: 'white' }}
                >
                  {label}
                </Link>
              ) : (
                <span onClick={() => handleNavigation(id)}>{label}</span>
              )}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Header;
