import React from 'react';
import { Box, Grid, Text, Image, Heading } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import bgImage from '../assets/mango_bg2.png';

const Banner = () => {
  return (
    <Grid
      id="banner"
      templateColumns={{ base: '1fr', md: '1fr 1fr' }}
      gap={0}
      p={0}
      color="primary.500"
      boxShadow="lg"
      justifyContent="center"
      textAlign={{ base: 'center', md: 'center' }} // Center-align both on medium screens
      bg="primary.500"
    >
      {/* Left side content */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems={{ base: 'center', md: 'center' }} // Center-align on medium screens
        justifyContent="center"
        color="white"
        textAlign="center"
        h="100%"
      >
        <div style={{ padding: '10px' }}>
          <Heading
            fontFamily="sans-serif"
            textAlign="center" // Center-align the heading
          >
            <b>
              SIP INTO PURE JOY WITH <br /> COOL O !!
            </b>
          </Heading>
          <Text
            fontFamily="sans-serif"
            textAlign="center" // Center-align the text
          >
            <strong>
              Crafted from the Finest Ingredients, Always 100% Natural !! A
              Purity Revolution with Natural Colors, Flavors & Premium
              Ingredients !!
            </strong>
          </Text>
        </div>
      </Box>

      {/* Right side image */}
      <Image
        display={{ base: 'none', md: 'block' }} // Hide on smaller screens
        src={bgImage}
        alt="Image 2"
        w="90%"
        h="100%"
        objectFit="contain"
      />
    </Grid>
  );
};

export default Banner;
