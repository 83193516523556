import React from 'react';
import { Box, Flex, Heading, Text, Image } from '@chakra-ui/react';
import CardImage1 from '../assets/drop.png';
import CardImage2 from '../assets/badge.png';
import CardImage3 from '../assets/hand.png';

const AboutUs = () => {
  const cardHeight = '320px'; // Increase the height for a more rectangular shape

  return (
    <Box bg="white" id="about" p={8} textAlign="center" minH="75vh">
      <Box p={4} mt={2}>
        <Heading mb={8} color="#304c94">
          ABOUT ICEPOPS
        </Heading>
        <Text fontWeight="bold" color="#304c94">
          The Ice Pops are reminiscent of Pepsi cola we used to have during our
          childhood days, who were born in the 90s or before would feel
          nostalgic! But at a tender age, only a few would know they were loaded
          with artificial flavors, artificial colors, & preservatives with
          extremely bad water and created in unhygienic conditions. But to get
          over that we have found a way to get back into those memories by
          introducing COOL O Juicy Ice Pops which are completely hygienic & made
          up of Natural Flavors, Natural Colours, Natural Sweeteners, and no
          preservatives. Our ice pops contain the best quality ingredients with
          strictly followed quality guidelines which would be a refreshing treat
          for grandchildren to great grandparents The yummy icy treats we create
          are designed to eliminate the use of artificial flavors, artificial
          colours & preservatives. Our ambition is to establish Ice Pops as a
          global brand in the coming years.
        </Text>
      </Box>
      <Flex
        justify="space-around"
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'center', md: 'flex-start' }}
      >
        {/* Card 1 */}
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          boxShadow="md"
          p={4}
          mb={4} // Adjusted margin between cards
          mt={4} // Increased margin from the top edge
          h={cardHeight}
        >
          <Image src={CardImage1} alt="Card 1" maxH={48} objectFit="contain" />
          <Box p={4} mt={2}>
            <Text fontWeight="bold" mb={2} color="#304c94">
              Recognition and Revolution
            </Text>
            <Text color="#304c94">
              The brand which we have launched as "Cool O" comes from the Pepsi
              cola industry which is currently recognized as 'Ice Pops'.
            </Text>
          </Box>
        </Box>

        {/* Card 2 */}
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          boxShadow="md"
          p={4}
          mb={4} // Adjusted margin between cards
          mt={4} // Increased margin from the top edge
          h={cardHeight}
        >
          <Image src={CardImage2} alt="Card 2" maxH={48} objectFit="contain" />
          <Box p={4} mt={2}>
            <Text fontWeight="bold" mb={2} color="#304c94">
              100% Pure Ingredients!
            </Text>
            <Text color="#304c94">
              We make sure our product includes 100% natural colors, natural
              flavors & rich ingredients with completely purified water.
            </Text>
          </Box>
        </Box>

        {/* Card 3 */}
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          textAlign="center"
          display="flex"
          flexDirection="column"
          alignItems="center"
          boxShadow="md"
          p={4}
          mb={4} // Adjusted margin between cards
          mt={4} // Increased margin from the top edge
          h={cardHeight}
        >
          <Image src={CardImage3} alt="Card 3" maxH={48} objectFit="contain" />
          <Box p={4} mt={2}>
            <Text fontWeight="bold" mb={2} color="#304c94">
              Cutting-edge manufacturing
            </Text>
            <Text color="#304c94">
              Our manufacturing facility adheres to quality and safety
              requirements which ensures that products reflect our quality and
              commitment.
            </Text>
          </Box>
        </Box>
      </Flex>
      <Box p={4} mt={2}>
        <Heading mb={8} color="#304c94">
          FOUNDERS & TEAM
        </Heading>
        <Text fontWeight="bold" color="#304c94">
          Originated and developed by the savory wizard entrepreneurs,
          Mr.Manharbhai Patel who dedicated more than 4 decades to the ice cream
          & beverages industry, and Mr.Kekal Patel who carries a rich experience
          of 28 years in the same by the name of Mona Foods (Mona Ice Cream).
          Mr.Vishal Patel is a marketing genius who is carrying a great
          experience of 13 years in the Australian market and manages the brand
          in Australia. Mr.Prashant Khadke with a wise experience of 22 years in
          the field of trading & marketing of the FMCG products. All of them aim
          to establish "Cool O" into a global market across India, UAE &
          Australia!
        </Text>
      </Box>
    </Box>
  );
};

export default AboutUs;
