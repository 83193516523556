// ProductsSection.js
import React from 'react';
import { Flex, Heading, Box } from '@chakra-ui/react';
import popsicleImage from '../assets/background_products.jpg';
import mangoPop from '../assets/mango_pop.jpeg';
import cokePop from '../assets/coke_pop.jpeg';
import strawberryPop from '../assets/strawberry_pop.jpeg';
import allPop from '../assets/all_pop.jpeg';
import barfCoolo from '../assets/product1.jpeg';
import ProductBox from './ProductBox';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ProductsSection = () => {
  const products = [
    {
      src: mangoPop,
      name: 'Mango Pop',
      description:
        'Description of Mango Pop. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      src: cokePop,
      name: 'Coke Pop',
      description:
        'Description of Coke Pop. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      src: strawberryPop,
      name: 'Strawberry Pop',
      description:
        'Description of Strawberry Pop. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      src: allPop,
      name: 'All Pops',
      description:
        'Description of All Pop. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
    {
      src: barfCoolo,
      name: 'Cola Pops',
      description:
        'Description of All Pop. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
    },
  ];

  const sliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box id="products" bg="primary.500" p={8} textAlign="center">
      {/* <Flex direction="column" align="center" justify="center"> */}
      <Heading color="white" mb={6}>
        Our Products
      </Heading>
      <Slider {...sliderSettings}>
        {products.map((product, index) => (
          <ProductBox key={index} {...product} />
        ))}
      </Slider>
      {/* </Flex> */}
    </Box>
  );
};

export default ProductsSection;
