// GetInTouchSection.jsx
import React from 'react';
import { Box, Flex, Text, Image, Heading, Link } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaMapMarker } from 'react-icons/fa';
import mapImg from '../assets/address_coolo.png';
import locationIcon from '../assets/location_icon.png';
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaPhone,
  FaWhatsapp,
} from 'react-icons/fa';

const GetInTouchSection = () => {
  const cardStyle = {
    bg: 'white',
    p: 4, // Adjusted padding
    boxShadow: 'lg',
    textAlign: 'center',
  };

  return (
    <Box id="contact" {...cardStyle}>
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <Heading mb={8} color="#304c94">
          Contact Us
        </Heading>
        <Flex flexDirection="column" alignItems="center">
          <Link
            href="https://www.google.com/maps/search/?api=1&query=18.3842998,73.856873&query_place_id=ChIJpSeHji3twjsR9xR1v68kZe4"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Box
              maxW="sm"
              overflow="hidden"
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              p={4}
              mb={2} // Adjusted margin between cards
              mt={2} // Increased margin from the top edge
            >
              <Image
                src={locationIcon}
                alt="Location Icon"
                maxH={48}
                objectFit="contain"
              />
              <Box p={4} mt={2}>
                <Text>
                  Kusum Foods & Beverages, 452 At/post-Shindewadi, Pune-Satara
                  Road, Taluka Bhor District Pune-412205.
                </Text>
              </Box>
            </Box>
          </Link>

          <Box>
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <Link
                href="https://www.google.com/maps/search/?api=1&query=18.3842998,73.856873&query_place_id=ChIJpSeHji3twjsR9xR1v68kZe4"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image src={mapImg} alt="Location" />
              </Link>
            </motion.div>
          </Box>
        </Flex>
      </motion.div>
    </Box>
  );
};

export default GetInTouchSection;
