import React from 'react';
import AboutUs from '../components/AboutUs';
import Banner from '../components/Banner';
import GetInTouchSection from '../components/GetInTouchSection';
import ProductsSection from '../components/ProductSection';

const Home = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <ProductsSection />
      <GetInTouchSection />
    </>
  );
};

export default Home;
