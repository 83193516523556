// ProductBox.js
import React from 'react';
import { Box, Image, Text } from '@chakra-ui/react';
import { motion } from 'framer-motion';

const ProductBox = ({ src, name, description }) => {
  const boxStyle = {
    display: 'inline-block',
    width: 'auto', // Adjust the size as needed
    borderRadius: 'lg', // Rounded edges
    overflow: 'hidden',
    m: 1, // Adjust the margin between products (reduced from 2 to 1)
    boxShadow: 'md',
    padding: '10px',
  };

  const imageStyle = {
    p: 4,
    width: '100%',
    height: 'auto', // Maintain aspect ratio
    borderRadius: 'lg', // Rounded edges for the image
  };

  const textStyle = {
    p: 4,
    fontWeight: 'bold',
    fontSize: 'md',
    color: 'white',
    position: 'relative',
  };

  return (
    <div style={boxStyle}>
      <Image src={src} alt={name} style={imageStyle} />
      <Box {...textStyle}>
        <Text>{name}</Text>
      </Box>
    </div>
  );
};

export default ProductBox;
